import { addDays, format } from "date-fns";
import { PRICING, PRICES } from "../../constants/pricing";
import React from "react";

const SUB_TYPES = {
  "8": `1 Month (billed $${PRICING.MONTHLY} every month)`,
  "9": `3 Month (billed $${PRICING.TRI_MONTHLY} every 3 months)`,
  "10": `6 Month (billed $${PRICING.SEMI_ANNUALLY} every 6 months)`,
};

function moneyCalculation(amount, discount) {
  const discountToTakeOff = Number(amount * (discount / 100)).toFixed(2);

  return discountToTakeOff;
}

export default function ReactivateSubscriptionForm({ onEditing, onChanges }) {
  const [showUpgrade, setShowUpgrade] = React.useState(false);
  const [posting, setPosting] = React.useState(false);
  const [promoCode, setPromoCode] = React.useState("");
  const [promoCodeInvalid, setPromoCodeInvalid] = React.useState(null);
  const [selectedPromoCode, setSelectedPromoCode] = React.useState(null);
  const [subscriptionType, setSubscriptionType] = React.useState(null);
  const formatSubscriptionType = SUB_TYPES[subscriptionType] || "Not selected";

  async function checkPromoCode() {
    let validCode = false;
    setPosting(true);

    try {
      if (promoCode !== "") {
        const response = await fetch("/.netlify/functions/valid-promo-code", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            code: promoCode.trim(),
          }),
        });

        const data = await response.json();

        setPromoCodeInvalid(!data.valid);
        setSelectedPromoCode(data.code);
        setSubscriptionType(
          data.valid
            ? data.code.subscription_type
              ? data.code.subscription_type.toString()
              : subscriptionType
            : subscriptionType
        );

        if (data.valid === false) {
          validCode = false;
        } else {
          validCode = true;
          setPosting(false);
        }
      } else {
        validCode = true;
      }
    } catch (err) {
    } finally {
      setPosting(false);
    }

    return validCode;
  }

  const today = new Date();
  const chargeDate =
    promoCodeInvalid === false && selectedPromoCode !== null
      ? addDays(today, selectedPromoCode.num_days)
      : today;

  const priceInfo = {
    line1: `$${PRICING.MONTHLY} USD monthly`,
    line2: "monthly",
    line3: "1",
    line4: `$${PRICING.MONTHLY}/mo`,
    line5: "standard",
  };

  if (subscriptionType === "9") {
    priceInfo.line1 = `$${PRICING.TRI_MONTHLY} USD 3 month`;
    priceInfo.line2 = "in 3 months";
    priceInfo.line3 = "3";
    priceInfo.line4 = `$${PRICING.TRI_MONTHLY}/3mo`;
    priceInfo.line5 =
      selectedPromoCode &&
      selectedPromoCode.discount > 0 &&
      selectedPromoCode.subscription_type === 1
        ? "discounted"
        : "standard";
  } else if (subscriptionType === "10") {
    priceInfo.line1 = `$${PRICING.SEMI_ANNUALLY} USD 6 month`;
    priceInfo.line2 = "in 6 months";
    priceInfo.line3 = "6";
    priceInfo.line4 = `$${PRICING.SEMI_ANNUALLY}/6mo`;
    priceInfo.line5 = "standard";
  }

  let promoCodeBoxMessage = "";
  if (selectedPromoCode && selectedPromoCode.num_days !== 0) {
    promoCodeBoxMessage = `Applied! ${selectedPromoCode.num_days} days free
      trial, you will be charged on ${format(chargeDate, "MM/dd/yyyy")}`;
  } else if (selectedPromoCode && selectedPromoCode.discount > 0) {
    const chargeAmount = moneyCalculation(
      PRICES[selectedPromoCode.subscription_type],
      selectedPromoCode.discount
    );
    const term = priceInfo.line4.substring(
      priceInfo.line4.lastIndexOf("/") + 1
    );
    priceInfo.line4 = `$${chargeAmount}/${term}`;
    priceInfo.line6 = ` After the first billing period, price will revert to $${
      PRICES[selectedPromoCode.subscription_type]
    }/${term}.`;
    promoCodeBoxMessage = `Applied! $${selectedPromoCode.discount} discount to be applied, you will be charged $${chargeAmount}`;
  }

  let priceSummary = `${formatSubscriptionType}, charged on ${format(
    chargeDate,
    "MM/dd/yyyy"
  )}`;
  if (selectedPromoCode && selectedPromoCode.discount > 0) {
    const chargeAmount = moneyCalculation(
      PRICES[selectedPromoCode.subscription_type],
      selectedPromoCode.discount
    );
    priceSummary = priceSummary.replace(PRICING.TRI_MONTHLY, chargeAmount);
  }

  if (!showUpgrade) {
    return (
      <>
        <div>
          <strong>Plan Type: </strong>
          {priceSummary}{" "}
          <button
            className="no-promo"
            onClick={() => {
              onEditing(true);
              setShowUpgrade(true);
            }}
          >
            {subscriptionType === null ? "Select Plan" : "Change"}
          </button>
        </div>
        <div>Promo Code: {promoCode || null}</div>
      </>
    );
  }

  return (
    <div>
      <ul className="form-wrapper">
        <li className="form-row">
          <label htmlFor="subscriptionType">
            <strong>Subscription Plan * </strong>
            <div>
              <input
                name="subscriptionType"
                value={`8`}
                checked={subscriptionType === `8`}
                defaultChecked={subscriptionType === `8`}
                onChange={() => {
                  setSelectedPromoCode(null);
                  setPromoCode("");
                  setPromoCodeInvalid(null);
                  setSubscriptionType("8");
                }}
                type="radio"
              />{" "}
              1 Month ${PRICING.MONTHLY}
            </div>
            <div>
              <input
                name="subscriptionType"
                value={`9`}
                checked={subscriptionType === `9`}
                defaultChecked={subscriptionType === `9`}
                onChange={() => {
                  setSelectedPromoCode(null);
                  setPromoCode("");
                  setPromoCodeInvalid(null);
                  setSubscriptionType("9");
                }}
                type="radio"
              />{" "}
              3 Months ${PRICING.TRI_MONTHLY}
            </div>
            <input
              name="subscriptionType"
              value={`10`}
              checked={subscriptionType === `10`}
              defaultChecked={subscriptionType === `10`}
              onChange={() => {
                setSelectedPromoCode(null);
                setPromoCode("");
                setPromoCodeInvalid(null);
                setSubscriptionType("10");
              }}
              type="radio"
            />{" "}
            6 Months ${PRICING.SEMI_ANNUALLY}
          </label>
        </li>
        <li className="form-row">
          <label htmlFor="promoCode">
            <strong>Promo Code </strong>
            {promoCodeInvalid === true && (
              <p className="field-error">Invalid promo code.</p>
            )}
          </label>
          {promoCodeInvalid === false && selectedPromoCode !== null && (
            <li className="form-row">
              <label
                style={{
                  backgroundColor: "#FF9F1C",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <strong>{promoCodeBoxMessage}</strong>
              </label>
            </li>
          )}
          <input
            name="promoCode"
            value={promoCode}
            onChange={(e) => {
              setPromoCode(e.target.value);
            }}
            type="text"
            disabled={selectedPromoCode !== null ? "disabled" : ""}
          />
          {promoCodeInvalid === null && (
            <button
              className="fancy"
              // style={{ width: "25%", marginLeft: "auto" }}
              disabled={posting || promoCode.length === 0}
              onClick={checkPromoCode}
              type="button"
            >
              {posting ? "Applying..." : "Apply"}
            </button>
          )}
          {promoCodeInvalid !== null && (
            <button
              className="fancy"
              disabled={posting}
              type="button"
              onClick={() => {
                setSelectedPromoCode(null);
                setPromoCodeInvalid(null);
                setPromoCode("");
              }}
            >
              Remove
            </button>
          )}
        </li>
        <li className="form-row">
          <button
            className="fancy"
            // style={{ width: "25%", marginLeft: "auto" }}
            disabled={posting || promoCodeInvalid}
            type="button"
            onClick={() => {
              onChanges({ promo: promoCode, subType: subscriptionType });
              setShowUpgrade(false);
              onEditing(false);
            }}
          >
            Save (not charged yet)
          </button>
        </li>
      </ul>
    </div>
  );
}
