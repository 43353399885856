import * as ROUTES from "../../constants/routes";

import Layout from "../../components/Layout";
import PaymentSource from "../../components/Account/PaymentSource";
import React from "react";
import ReactivateSubscriptionForm from "../../components/Account/ReactivateSubscriptionForm";
import { navigate } from "gatsby";
import { useAuthUser } from "../../components/Session";

function CanceledAccountPage() {
  const authUser = useAuthUser();
  const [promoCode, setPromoCode] = React.useState(null);
  const [subscriptionType, setSubscriptionType] = React.useState(null);
  const [posting, setPosting] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const handleChanges = React.useCallback(({ promo, subType }) => {
    if (promo !== null || promo !== undefined) {
      setPromoCode(promo);
    }
    if (subType) {
      setSubscriptionType(subType);
    }
  }, []);

  const handleEditing = (value) => setEditing(value);

  const submit = React.useCallback(async () => {
    if (posting) return;
    setPosting(true);
    try {
      const response = await fetch(
        "/.netlify/functions/reactivate-subscription",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            promoCode,
            subType: subscriptionType,
            customerId: authUser.stripe_id,
            uid: authUser.uid,
            email: authUser.email,
            username: authUser.username,
          }),
        }
      );

      const data = await response.json();
      if (data.status === "success") {
        setMessage("Success! Your account has been reactivated.");
        navigate(ROUTES.GETTING_STARTED);
      } else {
        setMessage(data.error);
      }
    } catch (err) {
      setMessage(err);
    } finally {
      setPosting(false);
    }
  }, [promoCode, subscriptionType, setPosting, authUser, posting]);

  React.useEffect(() => {
    if (authUser) {
      if (
        authUser.subscription_status === "trialing" ||
        authUser.subscription_status === "active"
      ) {
        navigate(ROUTES.HOME);
      }
    }
  }, [authUser]);

  if (!authUser) {
    return "Loading...";
  }

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                Account Canceled
              </h2>
              <p>
                Your account has been canceled, most likely because you didn't
                renew your membership. If you wish to view the weekly workouts
                and other features again, please subscribe to a membership.
              </p>
              <br />
              <PaymentSource />
              <ReactivateSubscriptionForm
                onChanges={handleChanges}
                onEditing={handleEditing}
              />

              {editing === false && (
                <button
                  className="fancy"
                  disabled={
                    posting || promoCode === null || subscriptionType === null
                  }
                  type="button"
                  onClick={submit}
                >
                  reactivate!
                </button>
              )}
              <br />
              <strong>{message}</strong>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const CancelPageFinal = () => (
  <Layout>
    <CanceledAccountPage />
  </Layout>
);

export default CancelPageFinal;
